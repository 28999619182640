<template>
  <div class='channel-statistics'>
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>

    <div class="channel-statistics__container">
      <p class="title-mark pl12">渠道码： {{statistics.name}}</p>
      <div class="statistics">
        <div class="statistics-item">
          <svg-icon class="icon" type="iconjinrixinzeng"></svg-icon>
          <a-statistic title="今日添加客户数">
            <template #formatter>
              <count-to :endVal="Number(statistics.todayAddCnt)" :duration="1000"></count-to>
            </template>
          </a-statistic>
        </div>
        <div class="statistics-item">
          <svg-icon class="icon" type="icontodayjian"></svg-icon>
          <a-statistic title="今日流失客户数">
            <template #formatter>
              <count-to :endVal="Number(statistics.todayLostCnt)" :duration="1000"></count-to>
            </template>
          </a-statistic>
        </div>
        <div class="statistics-item">
          <svg-icon class="icon" type="iconleijiadd"></svg-icon>
          <a-statistic title="累计添加客户数">
            <template #formatter>
              <count-to :endVal="Number(statistics.totalAddCnt)" :duration="1000"></count-to>
            </template>
          </a-statistic>
        </div>
        <div class="statistics-item">
          <svg-icon class="icon" type="iconleijiliushi"></svg-icon>
          <a-statistic title="累计流失客户数">
            <template #formatter>
              <count-to :endVal="Number(statistics.totalLostCnt)" :duration="1000"></count-to>
            </template>
          </a-statistic>
        </div>
      </div>
      <a-form layout="inline" :model="searchList">
        <a-form-item>
          <a-dropdown :trigger="['click']">
            <span class="ant-dropdown-link" @click.prevent>
              {{echartsData.status}}
              <CaretDownOutlined :style="{color: '#bebebe', marginLeft: '8px'}" />
            </span>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a @click="changeType('add')">新增客户数</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="changeType('lost')">流失客户数</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-form-item>
      </a-form>
      <div id="customer-echarts"></div>
    </div>
    <div id="echarts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/grid";

import _ from "lodash";
import { reactive } from "vue";
import { Statistic } from "ant-design-vue";
import { CountTo } from "vue-count-to2";

import RouterHeader from "@/components/RouterHeader.vue";
import SvgIcon from "@/components/SvgIcon";

import StatisticsApi from "@/service/api/statistics";

export default {
  name: "ChannelStatistics",
  components: {
    RouterHeader,
    SvgIcon,
    AStatistic: Statistic,
    CountTo,
  },
  setup() {
    const routerHeaderInfo = [
      {
        path: "/conduction",
        name: "渠道活码",
      },
      {
        name: "数据统计",
      },
    ];

    const searchList = reactive({
      id: "",
      type: "",
      days: 30,
    });
    const echartsData = reactive({
      xAxisData: [],
      status: "",
      seriesData: [],
    });
    return {
      routerHeaderInfo,
      searchList,
      echartsData,
    };
  },
  data() {
    return {
      statistics: {
        todayAddCnt: 0,
        todayLostCnt: 0,
        totalAddCnt: 0,
        totalLostCnt: 0,
      },
      dateList: [],
      chart: {},
    };
  },
  mounted() {
    this.searchList.id = this.$route.query.id;
    this.getStatisticsValue(this.$route.query.id);
    this.changeType("add");
    const that = this;
    try {
      window.onresize = function temp() {
        that.chart.resize();
      };
    } catch (error) {
      console.log(error);
    }
  },
  beforeRouteLeave(to, from, next) {
    try {
      this.chart.dispose();
    } catch (err) {
      next();
    }
    next();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let channelChart = echarts.init(document.getElementById("echarts"));
      // 绘制图表
      channelChart.setOption({
        color: ["#31A1FF"],
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "4%",
          right: "4%",
        },
        xAxis: {
          boundaryGap: false,
          data: this.echartsData.xAxisData,
        },
        yAxis: {},
        series: [
          {
            name: this.echartsData.status,
            type: "line",
            data: this.echartsData.seriesData,
            showSymbol: false,
            symbolSize: 4, //折线点的大小
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(49,161,255,0.25)" },
                  { offset: 1, color: "#fff" },
                ]),
              },
            }, //填充区域样式
            lineStyle: {
              width: 1.5,
            },
          },
        ],
      });
      this.chart = channelChart;
    },
    async getStatisticsValue(id) {
      this.statistics = await StatisticsApi.getCustomerStatsValue({ id: id });
    },
    getLastDays() {
      let date1 = new Date();
      let dateList = new Map();
      for (let i = 29; i >= 0; i--) {
        let date2 = new Date(date1);
        let key = this.$f
          .datetime(date2.setDate(date1.getDate() - i))
          .substring(0, 10);
        let value = 0;
        dateList.set(key, value);
      }
      this.dateList = dateList;
    },
    async getStatisticsData() {
      this.getLastDays();
      const data = await StatisticsApi.getCustomerStatsRange(this.searchList);
      console.log(data);
      _.forOwn(data, (value, key) => {
        this.dateList.set(key, Number(value));
      });

      this.echartsData.xAxisData = [...this.dateList.keys()];
      this.echartsData.seriesData = [...this.dateList.values()];
    },
    async changeType(type) {
      this.searchList.type = type;
      this.echartsData.status =
        this.searchList.type === "add" ? "新增客户数" : "流失客户数";

      await this.getStatisticsData();
      this.drawLine();
    },
  },
};
</script>
<style lang='less' scoped>
.icon {
  margin-right: @spacing-6x;
  width: @spacing-12x;
  height: @spacing-12x;
  font-size: 48px;
}
</style>